// Set site info
// var siteinfo = document.getElementsByName("siteinfo")[0];
// var site_id = siteinfo.getAttribute("data-siteid");
// var lang = siteinfo.getAttribute("data-sitelang");



$(document).ready(function ()
{
	/*
	 *	SEARCH
	 */

	$('.searchbox__submit button').click(function(e){
		var $search_input = $('.searchbox__input', $(this).parent().parent());

		if ($search_input.val() == '') {
			e.preventDefault();
			$search_input.focus();
		}
	});
	/*
	$('.goto-search').click(function(){
		$('.searchbox__input').focus();
	});
	*/



	/*
	 *	CALENDAR
	 */

	var data_events = false;

	if ($('#datepicker-home').length > 0) {
		$.datepicker.setDefaults(
			$.extend(
				{'dateFormat':'dd-mm-yy'},
				$.datepicker.regional['nl-BE']
			)
		);

		data_events = JSON.parse($('.datepicker-home-data').attr('data-links'));

		$('#datepicker-home').datepicker({
			async: false,
			prevText: '<',
			nextText: '>',
			firstDay: 1,
			beforeShowDay: function (date){
				var cur_date = date;

				var tz_offset = Number(cur_date.getTimezoneOffset()*60); // offset timezone

				cur_date.setHours(0,0,0);
				var cur_timestamp = String(Math.floor(cur_date.getTime() / 1000) - tz_offset);

				if (data_events[cur_timestamp]) {
					return [ true, 'highlight', data_events[cur_timestamp]['title'] ];
				} else {
					return true;
				}
			},
			onChangeMonthYear: function(year, month, datepicker) {
				var starttime = new Date(year, month-1, 0);
				var endtime = new Date(year, month, 0);

				loadEvents(starttime.getTime()/1000, endtime.getTime()/1000);
			},
			onSelect: function(dateText, inst) {
				var date = new Date(inst.selectedYear, inst.selectedMonth, inst.selectedDay);

				var tz_offset = Number(date.getTimezoneOffset()*60); // offset timezone
				var date_unix = String(Math.floor(date.getTime()/1000) - tz_offset);

				window.location = data_events[date_unix]['url'];
			}
		});
	}

	function loadEvents(starttime, endtime) {
		var options = {};

		options.modulename = 'events';
		options.layout = 'json';
		options.fields = [
			['field_date','>=', starttime],
			['field_date','<=', endtime]
		];

		$.get('/ajax/'+site_id+'/'+lang+'/'+options.modulename, options, function(data){
			data_events = JSON.parse(data);
			$('#datepicker-home').datepicker('refresh');
		});

		return true;
	}



	/*
	 *	RICH-CONTENT
	 */

	// Lightbox
	$('.lightbox').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		margin: [20, 60, 20, 60],
		padding: 0,
		titleShow: true,
		width       : 960,
	    height      : 540,
	    aspectRatio : true
	});

	$('.lightbox-media').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true,
		helpers : {
			media : {}
		}
	});

	// Youtube/Vimeo thumbs
	$(".rc-video__thumb").not('.lightbox-media').click( startVideo );
	$(".rc-video__overlay").not('.lightbox-media').click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			if( src.indexOf('?') > -1 )
			{
				iframe.attr('src', src + '&autoplay=1');
			}
			else
			{
				iframe.attr('src', src + '?autoplay=1');
			}
		}

		$('.rc-video__overlay', block.parent()).fadeOut(150);
		$('.rc-video__thumb', block.parent()).fadeOut(150);
	}

	// Contentblocks slider
	$('.rc-article-slider').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	// Quote slider
	$('.rc-quote-slider__slick').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});
});

$(window).on('load', function ()
{
	/*
	 *	SLIDER DETAIL
	 */

	$('.slider-detail').slick({
		nextArrow: '<button type="button" class="slick-next"></button>',
		prevArrow: '<button type="button" class="slick-prev"></button>'
	});

	/*$('.thumb').click(function(e){
		e.preventDefault();
		var index = $(this).parent().index();
		$('.slider-detail').slick('slickGoTo', index);
	});*/

	// Image slider (options in data-slick)
	$('.rc-image-slider__slick').slick();
});



// CAPTCHA
var captcha_validated = false;

function onSubmitreCAPTCHA(response)
{
	captcha_validated = true;
	$('.rc-form form').submit();
}

function validateForm(event)
{
	event.preventDefault();
	var form = this;

	var validator = $(form).data('bs.validator');
	validator.validate();

	if (captcha_validated && !validator.hasErrors()) {
		form.submit();
	} else if(!captcha_validated) {
		grecaptcha.reset();
		grecaptcha.execute();
	}
}



/*
 *  RESIZE & SCROLL HANDLER
 */

function onResize() {
	/*
	if ($('#datepicker-home').length) {
		if ($(window).width() > 991) {
			var w_datepicker = $('#datepicker-home').outerWidth();
			var w_firstitem = $('.navbar--main .nav-item:first').outerWidth();

			$('.navbar--main .navbar-nav').css('justify-content', 'flex-start');
			$('.navbar--main .navbar-nav').css('padding-left', w_datepicker-w_firstitem+'px');
		} else {
			$('.navbar--main .navbar-nav').css('justify-content', 'center');
			$('.navbar--main .navbar-nav').css('padding-left', 0);
		}
	}
	*/
}

function onScroll() {
	// code to execute on scroll
}

/* */

function timerTick () {
	if (didResize) { onResize(); }
	didResize = false;

	if (didScroll) { onScroll(); }
	didScroll = false;
}

var didResize = false;
var didScroll = false;

$(window).on('load', function (){
	setInterval( timerTick, 20 );
	didResize = true;
	didScroll = true;
	timerTick();
});

$(window).resize( function() {
	didResize = true;
});

$(window).scroll( function() {
	didScroll = true;
});